<template>
  <div class="page-goods">

    <InputSearch code="F1" placeholder="Название / код / штрихкод" @submit="getData" ref="inputCode"/>
    <Loader :state="load"/>

    <template v-if="!load && goods.data.length">

      <table>
        <tr>
          <th>
            #
          </th>
          <th>
            Наименование
          </th>
          <th>
            Кол.
          </th>
        </tr>
        <tr v-for="(g,i) in goods.data" :key="g.id">
          <td class="num">
            {{ (goods.per_page * goods.current_page) - goods.per_page + i + 1 }}
          </td>
          <td>
            <small v-for="b in g.barcodes" :key="b.barcode">Штрихкод:
              <strong>{{ b.barcode }}</strong> - {{b.amount}} шт.<br>
            </small>
            <strong v-if="!g.barcodes.length" class="text-red">не назначен</strong>
            <br>
            <b>[{{g.code}}]</b> {{ g.name }}<br>
            <a href="#" @click.prevent="image_uri = g.photo">изображение</a>
          </td>
          <td><strong>{{ g.amount }}</strong></td>
        </tr>
      </table>

      <Paginator :current="+goods.current_page" :per_page="+goods.per_page" :total="+goods.total" @submit="getData"/>

    </template>
    <EmptyData v-else-if="!load"/>

    <ModalImage :uri="image_uri" @close="image_uri = ''"/>

  </div>
</template>

<script>
import {onMounted, onUnmounted, ref} from 'vue';
import Paginator from "@/components/Paginator";
import Loader from "@/components/Loader";
import InputSearch from "@/components/InputSearch";
import EmptyData from "@/components/EmptyData";
import ModalImage from "@/components/ModalImage";

export default {
  components: {ModalImage, EmptyData, InputSearch, Loader, Paginator},
  setup(props, {emit}) {
    emit('meta', {title: 'Товары', back: '/'})

    let load = ref('Загрузка...')
    let goods = ref([])
    let image_uri = ref('')
    let search = ''

    const getData = async (params = {}) => {
      params.page = params.page ? params.page : 1
      params.search !== undefined ? search = params.search : params.search = search

      load.value = 'Загрузка...'
      goods.value = await api.getGoods(params)
      load.value = ''
    }
    getData()

    let inputCode = ref(null)
    const keydown = (event) => {
      if (event.code === 'F13' && inputCode.value.input !== document.activeElement) {
        inputCode.value.input.value = ''
        inputCode.value.input.focus()
      }
    }
    onMounted(() => {
      document.addEventListener('keydown', keydown)
    })
    onUnmounted(() => {
      document.removeEventListener('keydown', keydown)
    })

    return {
      load, goods, getData, image_uri, inputCode
    }
  }
}
</script>
